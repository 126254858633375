import { useContext } from "react";
import { useFieldArray } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import useToggle from "../../hooks/useToggle";
import {
  HBK,
  parseErrorResponse,
  patchProperty,
  useProperty,
} from "../../http/dashboardApi";
import i18n from "../../i18n";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import FormFieldTranslations from "../../ui/FormFieldTranslations";
import Input from "../../ui/Input";
import Modal from "../../ui/Modal";
import Panel from "../../ui/Panel";
import Radio from "../../ui/Radio";
import Selected from "../../ui/Selected";
import Textarea from "../../ui/Textarea";
import {
  formatClassification,
  getClassificationFromString,
  omitEmptyFields,
  propertyRatings,
} from "../../utils";
import PropertyRating from "./PropertyRating";

interface Phone {
  number: string;
}

const Profile = () => {
  const { data: property, mutate } = useProperty(usePropertyIdParam());
  const dispatch = useContext(NotificationContext);
  if (!property) return null;

  const updateProfile = (body: HBK.PropertyBody) =>
    patchProperty(property.id, body)
      .then((res) => {
        mutate(res, false);
        dispatch(showSuccessNotification());
      })
      .catch((error: Error) => {
        dispatch(showErrorNotification(error));
      });

  return (
    <Panel
      title="Stammdaten"
      description="Diese Informationen werden öffentlich angezeigt."
    >
      <Form<
        Pick<
          HBK.Property,
          | "name"
          | "category"
          | "listing_status"
          | "description"
          | "email"
          | "guest_interaction"
        > & {
          classification: string;
          phone_numbers: Phone[];
        }
      >
        defaultValues={{
          name: property.name,
          category: property.category,
          classification: formatClassification(
            property.rating,
            property.is_superior,
          ),
          listing_status: property.listing_status,
          description: property.description,
          email: property.email,
          guest_interaction: property.guest_interaction,
          phone_numbers: property.phone_numbers.map((p) => {
            return { number: p };
          }),
        }}
        onSubmit={(values) =>
          updateProfile({
            name: values.name,
            category: values.category,
            ...getClassificationFromString(values.classification),
            listing_status: values.listing_status,
            description: omitEmptyFields(values.description),
            email: values.email,
            phone_numbers: values.phone_numbers.map((p) => p.number),
            guest_interaction: values.guest_interaction,
          })
        }
      >
        {({ register, formState: { errors }, watch, control, getValues }) => {
          const { fields, replace, remove } = useFieldArray({
            control,
            name: "phone_numbers",
            rules: { required: true },
          });

          return (
            <>
              <FormField
                label="Name"
                helpText="Der Name der Unterkunft."
                error={errors.name}
              >
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="Hotel Post"
                    {...register("name", {
                      required,
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField label="Kategorie" error={errors.category}>
                {({ required, isInvalid }) =>
                  HBK.propertyCategories.map((c) => (
                    <Radio
                      key={c}
                      label={i18n.property.category[c]}
                      {...register("category", {
                        required,
                      })}
                      defaultChecked={property.category === c}
                      value={c}
                      isInvalid={isInvalid}
                    />
                  ))
                }
              </FormField>
              <FormField label="Klassifizierung" error={errors.classification}>
                {({ required, isInvalid }) =>
                  propertyRatings.map(([rating, isSuperior]) => {
                    const value = formatClassification(rating, isSuperior);
                    return (
                      <Radio
                        key={value}
                        label={
                          <PropertyRating
                            category={watch("category")}
                            rating={rating}
                            isSuperior={isSuperior}
                          />
                        }
                        {...register("classification", {
                          required,
                        })}
                        value={value}
                        defaultChecked={
                          formatClassification(
                            property.rating,
                            property.is_superior,
                          ) === value
                        }
                        isInvalid={isInvalid}
                      />
                    );
                  })
                }
              </FormField>
              <FormField
                label="Beschreibung"
                helpText="Beschreiben Sie Ihre Unterkunft in ein paar kurzen Sätzen."
              >
                {({ labelId }) => (
                  <FormFieldTranslations
                    languages={property.languages}
                    errors={errors.description}
                    labelId={labelId}
                  >
                    {({ language, isInvalid, labelId }) => (
                      <Textarea
                        id={labelId}
                        rows={6}
                        placeholder={getDescriptionPlaceholder(language)}
                        isInvalid={isInvalid}
                        {...register(`description.${language}`)}
                      />
                    )}
                  </FormFieldTranslations>
                )}
              </FormField>
              <FormField label="E-Mail-Adresse" error={errors.email}>
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="example@example.com"
                    {...register("email", {
                      required,
                      validate: (value) =>
                        isEmail(value) ||
                        "Die E-Mail-Adresse ist nicht gültig.",
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField
                label="Telefonnummer"
                error={errors.phone_numbers?.root}
              >
                <Selected.List
                  fields={fields}
                  controls={
                    <AddPhoneNumberModal
                      propertyId={property.id}
                      phoneNumbers={fields}
                      onSubmit={(v) => replace(v)}
                    />
                  }
                >
                  {({ field, index }) => (
                    <Selected.ConfirmItem
                      key={field.id}
                      name={field.number}
                      confirm={{
                        title: "Telefonnummer entfernen",
                        description:
                          "Soll die Telefonnummer wirklich entfernt werden?",
                      }}
                      disabled={fields.length === 1}
                      onDelete={() => {
                        remove(index);
                        updateProfile({
                          phone_numbers: getValues("phone_numbers").map(
                            (e) => e.number,
                          ),
                        });
                      }}
                    />
                  )}
                </Selected.List>
              </FormField>
              <FormField label="Inseratsstatus" error={errors.listing_status}>
                {({ required, isInvalid }) =>
                  HBK.listingStatuses.map((status) => (
                    <Radio
                      key={status}
                      label={i18n.property.listingStatus[status]}
                      {...register("listing_status", {
                        required,
                      })}
                      defaultChecked={property.listing_status === status}
                      value={status}
                      isInvalid={isInvalid}
                      description={
                        i18n.property.listingStatusDescription[status]
                      }
                    />
                  ))
                }
              </FormField>
              <FormField
                label="Umgangsform mit Gästen"
                helpText="Konfigurieren Sie, wie der Gast in Kommunikationsmitteln, wie z.&nbsp;B. Buchungsbestätigungsmails oder Widgets angesprochen werden soll."
              >
                {({ required, isInvalid }) =>
                  HBK.guestInteractions.map((interaction) => (
                    <Radio
                      key={interaction}
                      label={i18n.property.guestInteraction[interaction]}
                      {...register("guest_interaction", {
                        required,
                      })}
                      defaultChecked={
                        property.guest_interaction === interaction
                      }
                      value={interaction}
                      isInvalid={isInvalid}
                    />
                  ))
                }
              </FormField>
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

export default Profile;

const getDescriptionPlaceholder = (language: HBK.Language) => {
  switch (language) {
    case "de":
      return "Charmantes Hotel mit großartigem Ausblick auf die Dolomiten.";
    case "en":
      return "Charming hotel with a great view of the Dolomites.";
    case "it":
      return "Affascinante hotel con una splendida vista sulle Dolomiti.";
    case "nl":
      return "Charmant hotel met een prachtig uitzicht op de Dolomieten.";
    case "fr":
      return "Charmant hôtel avec une magnifique vue sur les Dolomites.";
  }
};

interface AddPhoneNumberModalProps {
  propertyId: number;
  phoneNumbers: Phone[];
  onSubmit: (phoneNumbers: Phone[]) => void;
}

const AddPhoneNumberModal = ({
  propertyId,
  phoneNumbers,
  onSubmit,
}: AddPhoneNumberModalProps) => {
  const modal = useToggle();
  const dispatch = useContext(NotificationContext);

  return (
    <>
      <Button
        layout="hollow"
        buttonProps={{
          onClick: modal.open,
        }}
      >
        Eine weitere Nummer hinzufügen
      </Button>
      {modal.isOpen && (
        <Modal<{ phone: string }>
          title="Telefonnummer hinzufügen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={({ phone }) => {
            patchProperty(propertyId, {
              phone_numbers: [...phoneNumbers.map((e) => e.number), phone],
            })
              .then((res) => {
                onSubmit(
                  res.phone_numbers.map((p) => {
                    return { number: p };
                  }),
                );
                modal.close();
                dispatch(showSuccessNotification());
              })
              .catch((err: Error) => {
                const error = parseErrorResponse(err).error;
                dispatch(showErrorNotification(error, error.message));
              });
          }}
        >
          {({ register, formState: { errors } }) => (
            <FormField
              label="Telefonnummer"
              error={errors.phone}
              direction="column"
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  type="text"
                  placeholder="+39 0471 317840"
                  {...register("phone", {
                    required,
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
          )}
        </Modal>
      )}
    </>
  );
};
